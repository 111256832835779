import React, { useState } from "react";
import { Container, Button } from "../../globalStyles";
import { Link } from "react-router-dom";
import QandA from "./QandA";
import movingroad from "../../images/movingroad.gif";
import { Fade } from "react-reveal";

import {
  InfoSec,
  InfoRow,
  InfoRow2,
  InfoColumn,
  InfoColumn2,
  InfoColumn3,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  ImgWrapper2,
  ImgWrapper3,
  ImgWrapper4,
  Img,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
} from "./About.elements";

const About = ({ buttonLabel, description, headline, topLine, img, alt }) => {
  const [expanded, setExpanded] = useState(false);
  const [rotate, setRotate] = useState(false);

const handleClick = () => {
  setExpanded(!expanded);
  setRotate(!rotate);
};

  
  return (
    <>
      <InfoSec>
        <Container>
          <InfoRow>
            <InfoColumn2>
              <TextWrapper>
                <Fade left duration={3000} distance="40px">
                  <topLine>Where it all started...</topLine>
                  <Heading>{headline}</Heading>
                </Fade>
                <Fade right duration={4000} distance="40px">
                  <Subtitle>{description}</Subtitle>
                </Fade>
              </TextWrapper>
            </InfoColumn2>
            <InfoColumn2>
              <TextWrapper>
                <Fade top duration={2000} distance="50px">
                  <Fade left duration={3000} distance="500px"></Fade>
                  <ImgWrapper>
                    <Img2 src="https://i.imgur.com/ldi9A5H.png" alt={alt} />
                  </ImgWrapper>

                  <br></br>
                  <br></br>
                </Fade>
              </TextWrapper>
            </InfoColumn2>
          </InfoRow>
        </Container>
        <Container>
          <InfoRow>
            <InfoColumn3>
              <TextWrapper>
                <Fade top duration={3000} distance="40px">
                  <br></br>
                  <Heading>Our Current Songlist</Heading>
                  <br></br>
                </Fade>
              </TextWrapper>
            </InfoColumn3>
          </InfoRow>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
           <img
      width="50px"
      src="https://cdn-icons-png.flaticon.com/512/32/32195.png"
      onClick={handleClick}
      className={rotate ? "hover-img rotate" : "hover-img"}
    />
          </div>
          {expanded ? (
            <InfoRow>
              <InfoColumn2>
                <TextWrapper>
                  <Fade up duration={4000} distance="40px">
                    <Subtitle>Van Morrison - Baby, Please Don't Go</Subtitle>
                    <Subtitle>David Allan Coe - The Ride</Subtitle>
                    <Subtitle>
                      The Franklin County Trucking Company - Drivin' My Life
                      Away
                    </Subtitle>
                    <Subtitle>Sawyer Brown - Six Days On The Road</Subtitle>
                    <Subtitle>
                      John Denver - Take Me Home, Country Roads
                    </Subtitle>
                    <Subtitle>Glen Campbell - Gentle On My Mind</Subtitle>
                    <Subtitle>Jimmy Buffett - Margarita Ville</Subtitle>
                    <Subtitle>Bill Withers - Ain't No Sunshine</Subtitle>
                    <Subtitle>Steve Miller Band - Rock'n Me</Subtitle>
                    <Subtitle>Radiohead - Creep</Subtitle>
                    <Subtitle>
                      The Surreal McCoys - Folsom Prison Blues{" "}
                    </Subtitle>
                    <Subtitle>Billy Idol - White Wedding</Subtitle>
                    <Subtitle>The White Stripes - Seven Nation Army</Subtitle>
                    <Subtitle>
                      Chris Stapleton - Midnight Train To Memphis
                    </Subtitle>
                    <Subtitle>Nickleback - If Today Was Your Last Day</Subtitle>
                    <Subtitle>
                      The Rolling Stones - I Can't Get No Satisfaction
                    </Subtitle>
                    <Subtitle>
                      The Beatles - While My Guitar Gently Weeps
                    </Subtitle>
                    <Subtitle>The Beatles - Taxman</Subtitle>
                    <Subtitle>Soul Asylum - Runaway Train</Subtitle>
                  </Fade>
                </TextWrapper>
              </InfoColumn2>
              <InfoColumn2>
                <TextWrapper>
                  <Fade up duration={4000} distance="40px">
                    <Subtitle>3 Doors Down - Kryptonite</Subtitle>
                    <Subtitle>
                      The Hollies - Long Cool Woman (In a Black Dress)
                    </Subtitle>
                    <Subtitle>3 Doors Down - Here Without You</Subtitle>
                    <Subtitle>Kenny Wayne Sheperd - Blue On Black</Subtitle>
                    <Subtitle>Roy Orbison - Dream Baby Dream</Subtitle>
                    <Subtitle>The Ataris - The Boys of Summer</Subtitle>
                    <Subtitle>Roy Orbison - Pretty Woman</Subtitle>
                    <Subtitle>Three Dog Night - Never Been To Spain</Subtitle>
                    <Subtitle>Doyle Bramhall - Life By The Drop</Subtitle>
                    <Subtitle>Neil Young - Harvest Moon</Subtitle>
                    <Subtitle>Bad Company - Bad Company</Subtitle>
                    <Subtitle>Chris Isaak - Wicked Game</Subtitle>
                    <Subtitle>Eagles - Hotel California</Subtitle>
                    <Subtitle>Tom Petty - Runnin' Down A Dream</Subtitle>
                    <Subtitle>The Animals - House of the Rising Sun</Subtitle>
                    <Subtitle>
                      Tom Petty and the Heartbackers - Mary Jane's Last Dance
                    </Subtitle>
                    <Subtitle>Chris Stapleton - Tennessee Whiskey</Subtitle>
                    <Subtitle>The Beatles - Come Together</Subtitle>
                    <Subtitle>Red Hot Chili Peppers - Otherside</Subtitle>
                  </Fade>
                </TextWrapper>
              </InfoColumn2>
            </InfoRow>
          ) : null}
        </Container>
        {/*<br></br>
          <br></br>
          <Container>
          <InfoRow>
            
           <InfoColumn>
           
            <ImgWrapper>
           
              <Img2 src='https://www.flaticon.com/svg/static/icons/svg/3301/3301692.svg' alt=''/>
            </ImgWrapper>
            </InfoColumn>
          </InfoRow>
          </Container>
          <br></br>*/}
      </InfoSec>
      {/*<div class="flex-container">
  <div class="first flex-item">
    <h1><b style={{color: '#57a4ff'}}>C</b>leanliness</h1>
    <Img5 className='hapic' src='https://www.flaticon.com/svg/static/icons/svg/3022/3022961.svg' alt={alt} />
    <br></br>
    <p>hygiWay provides a <b>cleaner</b> option that will help protect our client’s customers from infectious diseases that can be contracted when a driver uses a public restroom, alley or urinates in a bottle.  hygiWay has created a Sanitation Standard Operating Procedure that systematically teaches the proper cleaning methodology throughout our network. hygiWay provides every Relief Station in our network a <i>Keep it Clean Kit </i>and training by our Director of Sanitation (Certified in HACCP, IJCSA, PCQI and GMP). The hygiWay application allows the driver to rank the cleanliness of our Relief Stations after every visit and our team performs Audits via ATP Meter readings. We believe our technology and training attacks the root cause of infectious diseases and can drastically slow the spread within a community.</p>
  </div>
  <div class="second flex-item">
    <h1><b style={{color: '#57a4ff'}}>E</b>quality</h1>
    <Img5 className='hapic' src='https://www.flaticon.com/svg/static/icons/svg/3555/3555053.svg' alt={alt} />
    <p>hygiWay promotes <b>equality</b> for female drivers by standardizing bathroom practices for all drivers that are in route and offer the same restroom opportunities for everyone.  According to our Driver Survey, 25% of all female delivery drivers purposely avoid drinking liquids, including water, while in route due to fear they will have to use the bathroom.  One female driver explained how the lack of bathrooms is unfair, because men can use an empty water bottle and complete their route faster where women have to seek out a working bathroom, compromising their health and safety for time.  hygiWay levels the playing field which creates a healthier, happier, more productive workforce.</p>
  </div>
  <div class="third flex-item">
    <h1><b style={{color: '#57a4ff'}}>E</b>fficiency</h1>
    <Img5 className='hapic' src='https://www.flaticon.com/svg/static/icons/svg/3142/3142269.svg' alt={alt} />
    <p>hygiWay is more <b>efficient</b> and will increase productivity for delivery drivers by directing them to clean, safe bathrooms (Relief Stations) that are located on their route instead of spending 10 to 20 minutes in search. Each Relief Station has a designated parking spot specifically for the delivery driver only.</p>
  </div>
  <div class="fourth flex-item">
    <h1><b style={{color: '#57a4ff'}}>S</b>afety</h1>
    <Img5 className='hapic' src='https://www.flaticon.com/svg/static/icons/svg/3208/3208680.svg' alt={alt} />
    <p>hygiWay will provide security for vehicles while the driver is in the bathroom and harbor a <b>safe</b> convenient place for a driver to find relief instead of using an alley or public restroom. This will help prevent theft of packages.</p>
  </div>
        </div> */}

      {/* <Container> 
          <InfoRow>
          <InfoColumn>
          <TextWrapper>
          <Heading><b style={{color: '#57a4ff'}}>C</b>lean</Heading>
          <Subtitle>hygiWay provides a <b>cleaner</b> option that will help protect our client’s customers from infectious diseases that can be contracted when a driver uses a public restroom, alley or urinate in a bottle.  hygiWay has created a Sanitation Standard Operating Procedure that systematically teaches the proper cleaning methodology throughout our network. hygiWay provides every Relief Station in our network a <i>Keep it Clean Kit </i>and training by our Director of Sanitation (Certified in HACCP, IJCSA, PCQI and GMP). The hygiWay application allows the driver to rank the cleanliness of our Relief Stations after every visit and our team performs Audits via ATP Meter readings. We believe our technology and training attacks the root cause of infectious diseases and can drastically slow the spread within a community.</Subtitle>
          </TextWrapper>
          </InfoColumn>
          <InfoColumn>
              
            </InfoColumn>
          </InfoRow>

          <InfoRow2>
            <InfoColumn>
              <TextWrapper>
                <Heading><b style={{color: '#57a4ff'}}>E</b>quality</Heading>
                <Subtitle>hygiWay promotes <b>equality</b> for female drivers by standardizing bathroom practices for all drivers that are in route and offer the same restroom opportunities for everyone.  According to our Driver Survey, 25% of all female delivery drivers purposely avoid drinking liquids, including water, while in route due to fear they will have to use the bathroom.  One female driver explained how the lack of bathrooms is unfair, because men can use an empty water bottle and complete their route faster where women have to seek out a working bathroom, compromising their health and safety for time.  hygiWay levels the playing field which creates a healthier, happier, more productive workforce.  </Subtitle>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper>
                <Img2 className='van2' src='https://www.flaticon.com/svg/static/icons/svg/3555/3555053.svg' alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow2>

          <InfoRow>
            <InfoColumn>
              <TextWrapper>
                <Heading><b style={{color: '#57a4ff'}}>E</b>fficiency</Heading>
                <Subtitle>hygiWay is more <b>efficient</b> and will decrease delivery times for delivery drivers by directing them to clean, safe bathrooms (Relief Stations) that are located on their route instead of spending 10 to 20 minutes in search. Each Relief Station has a designated parking spot specifically for the delivery driver only. </Subtitle>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper>
                <Img2 className='van2' src='https://www.flaticon.com/svg/static/icons/svg/2639/2639269.svg' alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>

          <InfoRow2>
            <InfoColumn>
              <TextWrapper>
                <Heading><b style={{color: '#57a4ff'}}>S</b>afety</Heading>
                <Subtitle>hygiWay will provide security for vehicles while the driver is in the bathroom and harbor a <b>safe</b> convenient place for a driver to find relief instead of using an alley or public restroom.  </Subtitle>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper>
                <Img3 className='van2' src='https://www.flaticon.com/svg/static/icons/svg/3208/3208680.svg' alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow2>

        </Container>
      <br></br>
      <br></br>
      <Heading style={{'text-align': 'center'}}>Frequently Asked Questions</Heading>
      <QandA />*/}
    </>
  );
};

export default About;
