import React, { useState, useEffect } from "react";
import { Intro, About, RoadMap, TheTeam, Footer, Navbar } from "../../components";
import { IntroData, AboutData } from "../Data";
import { Element } from "react-scroll";
import {
  NavItem,
  NavLinks,
} from "../../components/Navbar/Navbar.elements"
import { Button } from "../../globalStyles";
import Modal from "./Modal"
function Home() {

  const [showModal, setShowModal] = useState(true);

  function handleModalClose() {
    setShowModal(false);
  }
  const [login, setLogin] = useState({
    showFullSite: true,
    showLogin: false,
    showAdmin: true
  });

  const [password, setPassword] = useState({
    entry: ""
  })

  function handleClick(e){
    console.log('working')
    console.log(login)
    setLogin({
      showFullSite: false,
      showLogin: true,
      showAdmin: false
    })
  }

  function handleFormSubmit(e){
    e.preventDefault()
    console.log('submitted')
    console.log(password.entry)
    if (password.entry === 'keepitclean123') {
      console.log('logged in!')
      setLogin({
        showFullSite: true
      })
    } else {
      alert('incorrect password, please try again!')
    }
  }

  useEffect(() => {
    function handleClickOutside(e) {
      if (
        e.target.className !== "modal-content" &&
        e.target.className !== "modal-close"
      ) {
        handleModalClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleModalClose]);


  return (
    <>
    {!login.showFullSite ?
      <header className="App-header">
        <img onclick={handleClick} src='https://i.imgur.com/KsNwqhM.png' className="App-logo" alt="logo" style={{height:'100px',"pointer-events": "all"}}/>
        <br></br>
        <p style={{color: '#FFFFFF'}}>
          thecoyotesband.com is under construction!
        </p>
        <img src='https://media3.giphy.com/media/1XgIXQEzBu6ZWappVu/giphy.gif?cid=ecf05e47clhca6y2s5sb4imnxnwbkf1i70y1tsc84lv34yco&rid=giphy.gif&ct=g' className="App-logo" alt="logo" />
        
      </header>
      : null}
      {login.showLogin ? 

      <div className='login'>
      <form onSubmit={handleFormSubmit} class="signup-form">
                  <label>
                    <input
                      type="text"
                      placeholder="password"
                      value={password.entry}
                      onChange={(e) => setPassword({ ...password, entry: e.target.value })}
                    />
                  </label>
                  <br></br>
                  <button class="signup-btn" type="submit" value="Submit">
                    Submit
                  </button>
                </form>
                </div>
                : null}
      {login.showFullSite ?       
      
      <div>

    
      <Navbar />
      
      <Element name="intro" className="element">
        
        <Intro {...IntroData} />
        
      </Element>
      
      <Element name="about" className="element">
        <About {...AboutData} />
      </Element>
      <Element name="theteam" className="element">
        <TheTeam />
      </Element>

      <Element name="roadmap" className="element">
        <RoadMap />
      </Element>
      <Element name="footer" className="element">
        <Footer />
      </Element>
      </div> 
      : null }
    </>
  );
}

export default Home;
