import React from "react";
import { SiRails } from "react-icons/si";
import { Container} from "../../globalStyles";
import { SiRedux, SiMaterialUi, SiMongodb, SiElectron } from "react-icons/si";
import { FaReact, FaNodeJs } from "react-icons/fa";

import {
  Button,
  ProjectTitle,
  DescriptionWrapper,
  Description,
  ProjectSection,
  ProjectWrapper,
  ProjectHeading,
  ProjectContainer,
  ProjectCard,
  ProjectCardInfo,
  IconsWrapper,
  ProjectLink,
  Icon,
} from "./RoadMap.elements";

import {
  InfoSec2,
  InfoColumn2,
  InfoColumn,
  InfoRow,
  TextWrapper,

  
} from "../About/About.elements";
import { Fade } from "react-reveal";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import StarIcon from '@material-ui/icons/Star';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import FlareIcon from '@material-ui/icons/Flare';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { Carousel } from 'react-carousel-minimal';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

const RoadMap = () => {
  const data = [
    {
      image: "https://i.imgur.com/Bl2SHjR.jpg",
      caption: "New Year's Eve 2021"
    },
    {
      image: "https://i.imgur.com/kfqimKv.jpg",
      caption: "New Year's Eve 2021"
    },
    {
      image: "https://i.imgur.com/wOi7nY4.jpg",
      caption: "New Year's Eve 2021"
    },
    {
      image: "https://i.imgur.com/j9kSIXl.jpg",
      caption: "New Year's Eve 2021"
    },
    {
      image: "https://i.imgur.com/CxWkIU8.jpg",
      caption: "New Year's Eve 2021"
    },
    {
      image: "https://i.imgur.com/lPtXJhv.jpg",
      caption: "New Year's Eve 2021"
    },
    {
      image: "https://i.imgur.com/aAk44Ia.jpg",
      caption: "New Year's Eve 2021"
    },
  ];
  
  

  const captionStyle = {
    fontSize: '1.5em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
  <>
    <ProjectSection>
    <Fade bottom duration={2000} distance="40px">
      <ProjectHeading>Media</ProjectHeading>
  </Fade>

      <Fade right duration={3000} distance="40px">
      <div className="vid">
                <iframe  className="iframe" src="https://www.youtube.com/embed/aObTUqNe8ls" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
      <Carousel
            data={data}
            time={3000}
        
            width="95vw"
            height="70vh"
            captionStyle={captionStyle}
            radius="10px"
            
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="none"
            slideImageFit="contain"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
          
              textAlign: "center",
              margin: "20px auto",
              padding: "0 10px 0"
          
              
            }}
          />
      </Fade>

      </ProjectSection>
      <InfoSec2>
      <Container>
        <InfoRow>
          <InfoColumn>
            <TextWrapper>
      <Fade right duration={3000} distance="40px">
        
      <div className="vid">
                <iframe  className="iframe" src="https://www.youtube.com/embed/M6w7sAozB0Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid">
                <iframe className="iframe" src="https://www.youtube.com/embed/bNFJR8TVQDI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid">
                <iframe className="iframe" src="https://www.youtube.com/embed/ppDcK9yWAF4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                
                </Fade>
                </TextWrapper>
                </InfoColumn>
                <InfoColumn2>
                <TextWrapper>
      <Fade left duration={3000} distance="40px">

                <div className="vid">
                <iframe className="iframe" src="https://www.youtube.com/embed/0uuVSr3L-pU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid">
                <iframe className="iframe" src="https://www.youtube.com/embed/LSs8PK92-B8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid">
                <iframe className="iframe" src="https://www.youtube.com/embed/VnqTZrEtj1o" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                </Fade>
                
                </TextWrapper>
                </InfoColumn2>
                <InfoColumn>
                <TextWrapper>
                <Fade right duration={3000} distance="40px">
      <div className="vid2">
                <iframe  className="iframe2" src="https://www.youtube.com/embed/M6w7sAozB0Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid2">
                <iframe className="iframe2" src="https://www.youtube.com/embed/LSs8PK92-B8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
             
                </Fade>
                </TextWrapper>
                </InfoColumn>
                <InfoColumn2>
                <TextWrapper>
      <Fade right duration={3000} distance="40px">

                <div className="vid2">
                <iframe className="iframe2" src="https://www.youtube.com/embed/0uuVSr3L-pU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid2">
                <iframe className="iframe2" src="https://www.youtube.com/embed/VnqTZrEtj1o" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid2">
                <iframe className="iframe2" src="https://www.youtube.com/embed/bNFJR8TVQDI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                <div className="vid2">
                <iframe className="iframe2" src="https://www.youtube.com/embed/ppDcK9yWAF4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                </div>
                </Fade>
                </TextWrapper>
                </InfoColumn2>

      </InfoRow>
      </Container>
      
      </InfoSec2>
      
    </>
  );
};

export default RoadMap;