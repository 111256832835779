import styled from "styled-components";

export const InfoSec = styled.div`
  color: #fff;
  padding: 55px 0;
  background: #101522;
  z-index: 3;
  position: relative;
  top: -5em;
`;
export const InfoSec2 = styled.div`
  color: #fff;
  padding: 10x 0;
  background: #101522;
  z-index: 3;
  position: relative;
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  z-index: 6;

  background: none;
  align-items: center;

  flex-direction: row-reverse;
  
`;

export const InfoRow2 = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  background: none;
  align-items: center;
  flex-direction: row;
`;

export const InfoColumn = styled.div`
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  background: none;
  padding-right: 15px;
  padding-left: 15px;

  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    background: none;
    justify-content: center;
  }
`;

export const InfoColumn2 = styled.div`
  margin-bottom: auto;
  padding-right: 15px;
  background: none;
  padding-left: 15px;
  
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
export const InfoColumn3 = styled.div`
  margin-bottom: auto;

  flex: 1;
  text-align: center;
  
  max-width: 100%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    
    flex-basis: 100%;
    display: flex;
  }
`;

export const TextWrapper = styled.div`
  // max-width: 300px;
  padding-top: 0px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;


  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
  }
`;

export const TopLine = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-shadow: 0 0 5px black;
  margin-bottom: 1px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 38px;
  z-index: 6;
  line-height: 1.1;
  font-weight: 600;
  text-align: center;
  text-shadow: 0 0 5px black;

  color: #ffffff;
`;

export const Subtitle = styled.p`
  // max-width: 440px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  text-shadow: 0 0 5px black;
  color: #1ffffff;
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  vertical-align: middle;
  position: relative;

`;

export const ImgWrapper2 = styled.div`
  max-width: 555px;
  position: relative;
  transform: scaleX(-1);
  display: flex;
  top: -2em;
  vertical-align: middle;
  @media screen and (max-width: 768px) {
    display: none;
  }
  
  
`;

export const ImgWrapper3 = styled.div`
  max-width: 555px;
  position: relative;
  vertical-align: middle;
`;

export const ImgWrapper4 = styled.div`
  max-width: 125px;
  position: absolute;
  left: 74%;
  top: 1em;
  transform: scaleX(-1);
  display: flex;
  vertical-align: middle;
  @media screen and (min-width: 768px) {
    display: none;
  }
  
  
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: top;
  margin-bottom: auto;
  max-height: 500px;
`;

export const Img2 = styled.img`
  max-width: 80%;
  margin: auto;
  border-radius: 50%;
  vertical-align: middle;
  max-height: 350px;

  
`;

export const Img3 = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 60%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Img4 = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 106%;
  vertical-align: top;
  display: flex;
  margin-bottom: auto;

  max-height: 550px;
`;

export const Img5 = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: flex;
  max-height: 100vh;
`;

export const Img6 = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 20%;
  display: flex;
  max-height: 20vh;
  float:left;
  margin-right:20px;
`;