import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Fade } from "react-reveal";

import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import {
  FooterContainer,
  FooterSubscription,
  FooterSubHeading,
  Form,
  FormInput,
  SubmitButton,
  InputLabel,
  FooterLinksWrapper,
  FooterLinksContainer,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  SocialIcons,
  SocialIconLink,
} from "./Footer.elements";

const Footer = () => {
  const [message, setMessage] = useState({
    name: "",
    email: "",
    text: "",
  });

  var service_id = "service_5bs9i4n";
  var template_id = "template_7xrul74";
  var user_id = "user_y2X1eKE5p06xM14UTxBGI";
  

  const sendEmail = (e) => {
    console.log(message)
    e.preventDefault();
    emailjs.send(service_id, template_id, message, user_id);
    setMessage({
      name: "",
      email: "",
      text: "A member of the band will be in touch soon!",
    });
  };

  return (
    <>
      <FooterContainer>
        <FooterSubscription>
          <Fade top duration={2500} distance="70px">
          <FooterSubHeading>Contact The Coyotes</FooterSubHeading>
          <Form onSubmit={sendEmail} >

            <FormInput
              name="name"
              type="text"
              placeholder="Name"
              value={message.name}
              onChange={(e) => setMessage({ ...message, name: e.target.value })}
            />
            <FormInput
              name="email"
              type="email"
              placeholder="Email"
              value={message.email}
              onChange={(e) =>
                setMessage({ ...message, email: e.target.value })
              }
            />

            <FormInput
              name="text"
              type="text"
              placeholder="Message"
              value={message.text}
              resize="vertical"
              onChange={(e) => setMessage({ ...message, text: e.target.value })}
            />

            <SubmitButton fontBig primary>Send</SubmitButton>
          </Form>
          </Fade>

        </FooterSubscription>
        {/*
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink
                href="https://www.linkedin.com/company/hygiway"
                target="_blank"
              >
                LinkedIn
              </FooterLink>
              <FooterLink
                href="https://www.facebook.com/hygiWay"
                target="_blank"
              >
                Facebook
              </FooterLink>
              <FooterLink
                href="https://www.instagram.com/hygiWay/"
                target="_blank"
              >
                Instagram
              </FooterLink>
              <FooterLink
                href="https://twitter.com/HygiWay"
                target="_blank"
              >
                Twitter
              </FooterLink>
            </FooterLinkItems>

            
          </FooterLinksWrapper>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Resources</FooterLinkTitle>
              <FooterLink
                href=""
                target="_blank"
              >
                Relief Station Apply
              </FooterLink>
              <FooterLink
                href=""
                target="_blank"
              >
                Relief Station Panel
              </FooterLink>
              <FooterLink
                href=""
                target="_blank"
              >
                Relief Station FAQ
              </FooterLink>
              <FooterLink
                href=""
                target="_blank"
              >
                Other
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>*/}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
              © 2023 The Coyotes
            </SocialLogo>

          </SocialMediaWrap>
        </SocialMedia>
      </FooterContainer>
    </>
  );
};

export default Footer;
