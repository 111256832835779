import React, { useState, useEffect } from "react";
import {
  Nav,
  NavBarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavItemBtn,
  NavBtnLink,
} from "./Navbar.elements";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../globalStyles";
import * as Scroll from "react-scroll";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavBarContainer>
            <NavLogo to="intro" onClick={closeMobileMenu}>
              <img src='https://i.imgur.com/42FEygW.png' height='70' alt='logo'/>
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks
                onClick={closeMobileMenu}
                  to="about"
                  offset={-100}
                  spy={true}
                  smooth={true}
                  duration={500}
                  >
                  The Coyote Story
                  </NavLinks>
                  </NavItem>
                
                <NavItem>
                  <NavLinks
                  onClick={closeMobileMenu}
                    to="theteam"
                    spy={true}
                    offset={-80}
                    smooth={true}
                    duration={500}
                  >
                    Meet The Band
                  </NavLinks>
                </NavItem>
              <NavItem>
                <NavLinks
                onClick={closeMobileMenu}
                  to="roadmap"
                  offset={-100}
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Media
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                onClick={closeMobileMenu}
                  to="footer"
                  spy={true}
                  offset={-80}
                  smooth={true}
                  duration={500}
                >
                  <Button onClick={closeMobileMenu} primary>Contact</Button>
                </NavLinks>
              </NavItem>
              {/* <NavItemBtn>
                {button ? (
                  <NavBtnLink
                    to="footer"

                  >
                    <div>
                    <Button className='test' primary>Contact</Button>
                    </div>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink
                    to="footer"
                  >
                    <Button onClick={closeMobileMenu} fontBig primary>
                      Dev Blog
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn> */}
            </NavMenu>
          </NavBarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
