import React from "react";
import { Container, Button } from "../../globalStyles";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from "./Intro.elements";
import {
  NavLogo,
  NavItem,
  NavLinks
} from "../Navbar/Navbar.elements";
import { Fade } from "react-reveal";

const Intro = ({ buttonLabel, description, headline, topLine, img, alt }) => {
  return (
    <>

      <InfoSec>
        <Container>
          {/* <InfoRow> */}
            {/* <InfoColumn> */}
              <TextWrapper>
              <Fade top duration={3000} distance="40px">
              <h1>Book reservations now!</h1>
        <NavItem style={{display: 'inline-block'}}>
                <NavLinks
                  to="footer"
                  spy={true}
                  offset={-80}
                  smooth={true}
                  duration={500}
                >
                  <Button  primary>Get in touch!</Button>
                </NavLinks>
              </NavItem>
                <TopLine>{topLine}</TopLine>
                </Fade>
                <Fade bottom duration={4000} distance="300px">
              <img className="logo" src='https://i.imgur.com/WJjgU2S.png' height='350' alt='logo'/>

              
              </Fade>
                {/*<Heading>{headline}</Heading>*/}
              </TextWrapper>
            {/* </InfoColumn> */}

            {/* <InfoColumn>
              <ImgWrapper>
                <Img src={img} alt={alt} />
              </ImgWrapper>
            </InfoColumn> */}
          {/* </InfoRow> */}
        </Container>
      </InfoSec>
      <img className='bgimg' src='https://i.imgur.com/puCjHjN.png' alt='amazon' style={{zIndex: '0'}}/>
    </>
  );
};

export default Intro;

// {isAboutMe ? (
//   <Link to="/files/emilio-resume.pdf" target="_blank" download>
//     <Button big fontBig primary={primary}>
//       {buttonLabel}
//     </Button>{" "}
//   </Link>
