import React from "react";
import { SiRails } from "react-icons/si";
import { SiRedux, SiMaterialUi, SiMongodb, SiElectron } from "react-icons/si";
import { FaReact, FaNodeJs } from "react-icons/fa";
import RonPic from '../../images/Ron.png'
import JohnPic from '../../images/John.png'
import { Fade } from "react-reveal";

import {
  Button,
  ProjectTitle,
  DescriptionWrapper,
  Description,
  ProjectSection,
  ProjectWrapper,
  ProjectHeading,
  ProjectContainer,
  ProjectCard,
  ProjectCardInfo,
  IconsWrapper,
  ProjectLink,
  Icon,
  Img
} from "./TheTeam.elements";

const TheTeam = () => {
  return (
    <ProjectSection >
      <ProjectWrapper>
      <Fade bottom duration={2000} distance="40px">
        <ProjectHeading>Meet The Band</ProjectHeading>
</Fade>
        <ProjectContainer>
        <Fade bottom duration={1500} distance="60px">
  <div class="card">
  <div class="card__inner">

    <div class="card__front">
    <Img src=/*{RonPic}*/{'https://i.imgur.com/jZaMf3w.png'} alt='ron' />
    <br></br>
    <ProjectTitle>Will Loberger</ProjectTitle>
    <Description>
                  Drummer
                </Description>
     </div>

    <div class="card__back">
       <p>I grew up in the suburbs of Detroit and have a big taste for Alternative/Punk/Rock.</p> 
    </div>
   </div>
</div>
</Fade>
<Fade bottom duration={2500} distance="70px">

<div class="card">
  <div class="card__inner">

    <div class="card__front">
    <Img src={'https://i.imgur.com/N2KmvX0.png'} alt='dr' />
    <br></br>
    <ProjectTitle>Micah McClaine</ProjectTitle>
    <Description>
                  Lead Guitarist + Vocalist
                </Description>
     </div>

    <div class="card__back">
       <p>My passion stems from playing in a band, live in front of people. I genuinely love all three of the other coyotes and we try to keep it light and not so serious, which I love. I’ve been playing guitar and piano for 15 years. I’m always thankful For anyone that comes to see us!</p> 
    </div>

   </div>
</div>
</Fade>
<Fade bottom duration={3500} distance="80px">

<div class="card">
  <div class="card__inner">

    <div class="card__front">
    <Img src=/*{JohnPic}*/{'https://i.imgur.com/1Mwc6X6.jpg'} alt='john' />
    <br></br>
    <ProjectTitle>Rich Turpin</ProjectTitle>
    <Description>
    Rhythm Guitarist
                </Description>
     </div>

    <div class="card__back">
       <p>Richard is a lifelong music lover and musician being influenced by Rock, Country, Bluegrass and Gospel. </p> 
    </div>

   </div>
</div>
<div class="card">
  <div class="card__inner">

    <div class="card__front">
    <Img src=/*{JohnPic}*/{'https://i.imgur.com/Al9qLkb.png'} alt='john' />
    <br></br>
    <ProjectTitle>James Burks</ProjectTitle>
    <Description>
    Bass Guitarist
                </Description>
     </div>

    <div class="card__back">
       <p>The only thing James loves more than his bass guitar is putting off writing his bio for this website! </p> 
    </div>

   </div>
</div>
</Fade>

          {/* <ProjectCard>
            <ProjectCardInfo>
              <ProjectTitle>Ronald Durso</ProjectTitle>
              <Img src={RonPic} alt='ron' />
              <DescriptionWrapper>
                <Description>
                  CEO + Founder
                </Description>
              </DescriptionWrapper>

            </ProjectCardInfo>
          </ProjectCard>
          <ProjectCard>
            <ProjectCardInfo>
              <ProjectTitle>John Loberger</ProjectTitle>
                          <Img src={JohnPic} alt='ron' />
              <DescriptionWrapper>
                <Description>
                  Project Manager + Software Engineer
                </Description>
              </DescriptionWrapper>
            </ProjectCardInfo>
          </ProjectCard>
          <ProjectCard>
            <ProjectCardInfo>
              <ProjectTitle>Coming Soon!</ProjectTitle>

              <DescriptionWrapper>
                <Description>
                  Director of Sanitation
                </Description>
              </DescriptionWrapper>
            </ProjectCardInfo>
          </ProjectCard> */}
        </ProjectContainer>
      </ProjectWrapper>
    </ProjectSection>
  );
};

export default TheTeam;