export const IntroData = {
  topLine: "Loudest in the night, all you can hear are",
  headline: "The Coyotes",
  description:
    "",
  buttonLabel: "See my Github",
  img: require("../images/svg-1.svg"),
  alt: "Image",
};

export const AboutData = {
  buttonLabel: "NA",
  description:
    "In December of 2019, a dream became reality when four guys decided to band together, literally. Cultivating from a combined passion for classic rock and roll and an immense amount of soul, The Coyotes was manifested with the intentions of bringing people together with good music. Explore our photos, videos, and more through this digital hub for all things Coyotes or even contact us directly via the contact form. ",
  headline: "The Coyote Story",
  topLine: "About hygiWay",
  img: require("../images/svg-2.svg"),
  alt: "Image",
};

export const projects = {
  projectOneName: "Servitodo",
  projectOneDescription:
    "Web app made to help connect professionals with potential customers",
  projectOneStack: "Made with React, Redux, Ruby on Rails, Material UI and JWT",
  projectOneLoom: "https://www.loom.com/share/0782c7ff097e49c3ab2d8b0e3e2b8817",
  projectOneGithub: "https://github.com/emilio-quintana-dev/servitodo-client",
  projectTwoName: "Cerealvis",
  projectTwoDescription:
    "Electron app made to visualize serial data coming from a port (IoT)",
  projectTwoStack: "Made with React, Node.js and Material UI",
  projectTwoLoom: "https://www.loom.com/share/264162506394494995a22879cce0ac30",
  projectTwoGithub: "https://github.com/emilio-quintana-dev/CerealVis",
  projectThreeName: "Evernot",
  projectThreeDescription:
    "Web app with user authentication made to write, save and email notes / reminders",
  projectThreeStack: "Made with React, Ruby on Rails and Material UI",
  projectThreeLoom:
    "https://www.loom.com/share/c75e72bc0d2848f7b897999599a7baf3",
  projectThreeGithub: "https://github.com/emilio-quintana-dev/notes-react-app",
};
